/* ContactPage.css */
.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
  }
  
  .contact-page h1 {
    margin-bottom: 20px;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .contact-info p {
    margin: 5px;
  }
  